import * as caching from "caching"
import * as timex from "timex"

const cache = new caching.Cache({ namespace: "novacloud.debug", ttl: timex.duration.iso("PT30D").toMillis() })

// check if alpha functionality is enabled.
export namespace alpha {
	export function enabled(): boolean {
		return cache.get("alpha") || false
	}

	export function toggle(upd: boolean): void {
		cache.set("alpha", upd)
	}
}

import * as svg from "./svg"
import * as theme from "layouts/theme"

const Icon = (props: svg.SVGProps) => {
	const { fill, stroke, ...rest } = props
	return (
		<svg.SVG
			width="17"
			height="17"
			viewBox="0 0 17 17"
			xmlns="http://www.w3.org/2000/svg"
			fill={fill || theme.colors.white}
			stroke={stroke || theme.colors.grey.dark10}
			{...rest}
		>
			<path
				d="M11.3125 6.62494L7.11747 10.3749L5.6875 9.09667M16 3.81244L16 13.1875C16 14.7408 14.7408 16 13.1875 16H3.8125C2.2592 16 1 14.7408 1 13.1875V3.81244C1 2.25914 2.2592 0.999939 3.8125 0.999939H13.1875C14.7408 0.999939 16 2.25914 16 3.81244Z"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</svg.SVG>
	)
}

export default Icon

import React, { useState } from "react"
import * as errors from "errors"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as debugx from "x/debugx"
import * as authzfacebook from "authz/facebook"
import * as api from "./api"

interface props {
	credential: authzfacebook.api.Credential
	onChange(upd: undefined | api.ClientAccount): Promise<undefined | api.ClientAccount>
}

export function Create(props: React.PropsWithChildren<props & layouts.containers.FlexProps>): JSX.Element {
	const { credential, onChange, ...rest } = props
	const [loading, setLoading] = useState(false)
	const [cause, setCause] = useState(undefined as errors.Cause)
	const [account, setAccount] = useState(
		debugx.alpha.enabled()
			? api.accounts.testdata({ authz_credentials_id: credential.id })
			: api.accounts.zero({ authz_credentials_id: credential.id }),
	)

	return (
		<layouts.containers.flex {...rest}>
			<layouts.containers.flex flex="1" styled>
				<layouts.forms.Container cause={cause} loading={loading} border={layouts.theme.borders.grey} {...rest}>
					<layouts.containers.flex m="auto" flexDirection="column">
						<layouts.forms.Group>
							<layouts.forms.Label width="20ch">Name</layouts.forms.Label>
							<inputs.Text
								placeholder="Required"
								defaultValue={account.description}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									setAccount({ ...account, description: evt.target.value })
								}
							/>
						</layouts.forms.Group>
						<layouts.forms.Group>
							<layouts.forms.Label width="20ch">Ad Account ID</layouts.forms.Label>
							<inputs.Text
								placeholder="Required"
								defaultValue={account.fb_account_id.toString()}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									setAccount({ ...account, fb_account_id: BigInt(evt.target.value) })
								}
							/>
						</layouts.forms.Group>
						<layouts.forms.Group>
							<layouts.forms.Label width="20ch">Page ID</layouts.forms.Label>
							<inputs.Text
								placeholder="Required"
								defaultValue={account.fb_page_id.toString()}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									setAccount({ ...account, fb_page_id: BigInt(evt.target.value) })
								}
							/>
						</layouts.forms.Group>
						<layouts.forms.Group>
							<layouts.forms.Label width="20ch">Manual Token</layouts.forms.Label>
							<inputs.Text
								placeholder="Required"
								defaultValue={account.manual_access_token.toString()}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									setAccount({ ...account, manual_access_token: evt.target.value })
								}
							/>
						</layouts.forms.Group>
						<layouts.forms.Group mt="auto" mb="0px" justifyContent="right">
							<layouts.buttons.outline
								mr="10px"
								onClick={(evt) => {
									onChange(undefined)
								}}
							>
								Cancel
							</layouts.buttons.outline>
							<layouts.buttons.primary
								onClick={(evt) => {
									setLoading(true)
									onChange(account)
										.catch((cause) => setCause(<errors.UnknownCause cause={cause} />))
										.finally(() => setLoading(false))
								}}
							>
								Create
							</layouts.buttons.primary>
						</layouts.forms.Group>
					</layouts.containers.flex>
				</layouts.forms.Container>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}

Create.defaultProps = {}

export default Create

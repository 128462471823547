import React, { useState } from "react"
import * as inputs from "inputs"
import * as sessions from "sessions"
import * as layouts from "layouts"
import * as oauth2 from "authz/oauth2"
import * as debugx from "x/debugx"
import * as api from "./api"

interface modalprops {
	scopes: string[]
	onChange(upd: api.Credential): void
}

export function Modal(props: React.PropsWithChildren<modalprops>): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const [loading, setLoading] = useState(false)
	const bearertoken = sessions.useToken()
	const [credential, setCredential] = useState(
		debugx.alpha.enabled()
			? api.authorization.requests.testdata({ scopes: props.scopes })
			: api.authorization.requests.zero({ scopes: props.scopes }),
	)

	return (
		<layouts.containers.box styled className="faceook authz" p="10px">
			<layouts.loading.screen loading={loading}>
				<Create current={credential} onChange={setCredential} />
				<layouts.containers.flex justifyContent="space-between" mt="5px">
					<layouts.buttons.outline onClick={() => mtoggle(undefined)}>Cancel</layouts.buttons.outline>
					<layouts.buttons.primary
						onClick={() => {
							setLoading(true)
							api.authorization
								.url(credential, bearertoken)
								.then(async (auth) => {
									return oauth2.pendingauth(auth).then((r) =>
										api.find(r.cid, bearertoken).then((r) => {
											props.onChange(r.credential)
											mtoggle(undefined)
										}),
									)
								})
								.catch(console.error)
								.finally(() => setLoading(false))
						}}
					>
						Authorize
					</layouts.buttons.primary>
				</layouts.containers.flex>
			</layouts.loading.screen>
		</layouts.containers.box>
	)
}

interface props {
	current: api.AuthorizeRequest
	onChange(upd: api.AuthorizeRequest): void
}
export default function Create(props: React.PropsWithChildren<props>): JSX.Element {
	const { current, onChange } = props

	return (
		<layouts.forms.Group className="mb-3">
			<layouts.forms.Label>Business Manager ID</layouts.forms.Label>
			<inputs.Text
				placeholder="facebook business id"
				defaultValue={current.cbmid}
				onChange={(evt) => {
					const updated = { ...current, cbmid: parseInt(evt.target.value, 10) }
					onChange(updated)
				}}
			/>
		</layouts.forms.Group>
	)
}

import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as typography from "typography"
import * as sessions from "sessions"
import * as errors from "errors"
import * as inputs from "inputs"
import * as pools from "ads/pools"
import * as textgen from "ads/textgen/api"
import * as brands from "brands"
import * as api from "./api"
import * as generated from "./adgen.responsive.search.generated"
import * as context from "./adgen.responsive.search.context"
import { Publisher, Publish } from "./adgen.responsive.search.publisher"
import BrandDetails from "ads/adgen/adgen.brand.details"
import Instructions from "./adgen.responsive.search.instructions"
import AdgenForm from "./adgen.responsive.search.form"
import { Container, BackLink } from "ads/adgen/adgen.content.layouts"

interface props {
	publish: React.ComponentClass<Publisher> | React.FunctionComponent<Publisher>
}

export function Display(props: props): JSX.Element {
	const adgenConstants = context.adgenconstants()
	const pool = pools.caching.useCached()
	const brand = brands.caching.useCached()
	const bearertoken = sessions.useToken()

	const [loading, setLoading] = useState(false)

	const [cause, setCause] = useState(undefined as errors.Cause)

	const [genctx, setGenctx] = useState(
		context.zero({
			metadata: api.ads.responsive.metadata({
				pool_id: pool.id,
				google_ads_client_account_id: pool.google_ads_client_account_id,
			}),
			generated: <Instructions />,
			currenturl: brand.domain,
		}),
	)

	const [mutations, setMutations] = useState([] as context.mutation[])
	const acceptedheadlines = genctx.content.headlines.map((v) => v.accepted)
	const accepteddescriptions = genctx.content.descriptions.map((v) => v.accepted)
	const assertions = [
		inputs.assertions.arrays.min(adgenConstants.minHeadlinesCount)(acceptedheadlines),
		inputs.assertions.arrays.unique((v: string) => v)(acceptedheadlines),
		inputs.assertions.arrays.every((v: string) => inputs.assertions.strings.max(30)(v))(acceptedheadlines),
		inputs.assertions.arrays.min(adgenConstants.minDescriptionCount)(accepteddescriptions),
		inputs.assertions.arrays.unique((v: string) => v)(accepteddescriptions),
		inputs.assertions.arrays.every((v: string) => inputs.assertions.strings.max(90)(v))(accepteddescriptions),
		inputs.assertions.strings.min(1)(genctx.metadata.description),
		genctx.content.urls.length === 0 ? inputs.assertions.strings.url()(genctx.currenturl || "") : undefined,
		acceptedheadlines.indexOf("") !== -1 ? <>empty headlines</> : undefined,
		accepteddescriptions.indexOf("") !== -1 ? <>empty descriptions</> : undefined,
	]

	const saveDisabled = inputs.assertions.failed(...assertions)
	const asyncupd = (...m: context.mutation[]) => {
		setMutations([...mutations, ...m])
	}

	useEffect(() => {
		if (mutations.length === 0) return
		const updated = context.mutate.apply(genctx, ...mutations)
		setGenctx(updated)
		setMutations([])
	}, [mutations])

	const finalurls =
		inputs.assertions.strings.url()(genctx.currenturl || "") === undefined
			? [...genctx.content.urls, genctx.currenturl]
			: genctx.content.urls

	const metadata = genctx.metadata
	const content = api.ads.responsive.content.zero({
		descriptions: genctx.content.descriptions.map((v) => v.accepted),
		headlines: genctx.content.headlines.map((v) => v.accepted),
		path1: genctx.content.path1,
		path2: genctx.content.path2,
		urls: finalurls,
	})

	return (
		<layouts.backgrounds.Grey className="google" flex="0 1 100%">
			<context.Provider value={genctx}>
				<layouts.containers.flex
					p="50px"
					className="ad-generation"
					width="100%"
					overflowX="hidden"
					overflowY="auto"
					flexDirection="column"
				>
					{cause}
					<errors.Boundary>
						<layouts.containers.flex width="100%" textAlign="left" boxShadow="none" pb="62px">
							<layouts.containers.flex display="table-cell">
								<typography.h3 color={layouts.theme.colors.grey.medium}>Google Ad Generation</typography.h3>
							</layouts.containers.flex>
						</layouts.containers.flex>
						<Container flexDirection="row">
							<layouts.containers.flex flex="2" flexDirection="column" pl="1px">
								<BrandDetails />
								<generated.default adgenConstants={adgenConstants} onChange={asyncupd} />
								<BackLink />
							</layouts.containers.flex>
							<layouts.containers.flex
								flexDirection="column"
								flex="5"
								mx="10px"
								verticalAlign="top"
								background={layouts.theme.colors.white}
								overflowY="auto"
							>
								<layouts.loading.screen loading={genctx.generating} flex="1" opacity="60%">
									{genctx.generated}
								</layouts.loading.screen>
							</layouts.containers.flex>
							<layouts.containers.flex
								overflowY="auto"
								flex="2"
								p="5px"
								borderRadius="10px"
								background={layouts.theme.colors.white}
							>
								<layouts.containers.flex flexDirection="column" width="100%" height="fit-content" flex="1 1 auto">
									<AdgenForm genctx={genctx} onChange={asyncupd} />
									<props.publish
										disabled={saveDisabled || loading}
										metadata={metadata}
										content={content}
										onChange={asyncupd}
										onPublish={async (pending) => {
											const post = pending
												.then(() => {
													setCause(undefined)
												})
												.catch((cause) =>
													setCause(
														<errors.Inline>
															<errors.Textual cause={cause} onClick={() => setCause(undefined)}>
																unable to publish google ads
															</errors.Textual>
														</errors.Inline>,
													),
												)
												.finally(() => {
													setLoading(false)
												})

											textgen.create.record(
												{
													samples: [...genctx.content.descriptions, ...genctx.content.headlines, ...genctx.rejected],
												},
												bearertoken,
											)
											return post
										}}
									/>
								</layouts.containers.flex>
							</layouts.containers.flex>
						</Container>
					</errors.Boundary>
				</layouts.containers.flex>
			</context.Provider>
		</layouts.backgrounds.Grey>
	)
}

Display.defaultProps = {
	publish: Publish,
}

import * as svg from "./svg"
import * as theme from "layouts/theme"

const Icon = (props: svg.SVGProps) => {
	const { fill, stroke, ...rest } = props
	return (
		<svg.SVG
			width="17"
			height="17"
			viewBox="0 0 17 17"
			xmlns="http://www.w3.org/2000/svg"
			fill={fill || theme.colors.white}
			stroke={stroke || theme.colors.grey.dark10}
			{...rest}
		>
			<path
				d="M7.5625 1V4.75C7.5625 5.26777 7.98223 5.6875 8.5 5.6875H12.25M7.72335 1H2.875C1.83947 1 1 1.83947 1 2.875V14.125C1 15.1605 1.83947 16 2.875 16H10.375C11.4105 16 12.25 15.1605 12.25 14.125V5.52665C12.25 5.02937 12.0525 4.55246 11.7008 4.20083L9.04918 1.54917C8.69755 1.19754 8.22063 1 7.72335 1Z"
				strokeWidth="1.5"
				fill="none"
			/>
		</svg.SVG>
	)
}

export default Icon

import React from "react"
import { Route, Routes } from "react-router-dom"
import * as layouts from "layouts"
import * as facebook from "./facebook"
import * as google from "./google"
import * as pools from "./pools"
import MetricsDisplay from "./metrics/display"
import * as suggestions from "./suggestions"
import * as studio from "./studio"
import * as routing from "./pools/routing"

export function GoogleAdsFromURL(): JSX.Element {
	return (
		<pools.CacheRoot>
			<google.Table width="100%" />
		</pools.CacheRoot>
	)
}

export function GoogleAdsResponsiveFromURL(): JSX.Element {
	return (
		<pools.CacheRoot>
			<google.ResponsiveTable width="100%" />
		</pools.CacheRoot>
	)
}

export function GoogleCreativeFromURL(): JSX.Element {
	return (
		<pools.CacheRoot>
			<google.Display />
		</pools.CacheRoot>
	)
}

export function FacebookAdsFromURL(): JSX.Element {
	return (
		<pools.CacheRoot>
			<facebook.Table width="100%" />
		</pools.CacheRoot>
	)
}

export function FacebookCreativeFromURL(): JSX.Element {
	return (
		<pools.CacheRoot>
			<facebook.Display />
		</pools.CacheRoot>
	)
}

export function MetricsFromURL(): JSX.Element {
	return (
		<pools.CacheRoot>
			<layouts.backgrounds.Grey flex="0 1 100%">
				<MetricsDisplay />
			</layouts.backgrounds.Grey>
		</pools.CacheRoot>
	)
}

export function SuggestionsGoogleFromURL(): JSX.Element {
	return (
		<pools.CacheRoot>
			<suggestions.Display>
				<suggestions.Google />
			</suggestions.Display>
		</pools.CacheRoot>
	)
}

export function SuggestionsFacebookFromURL(): JSX.Element {
	return (
		<pools.CacheRoot>
			<suggestions.Display>
				<suggestions.Facebook />
			</suggestions.Display>
		</pools.CacheRoot>
	)
}

export default function Routing(): JSX.Element {
	return (
		<Routes>
			<Route
				path="/:id/"
				element={
					<layouts.backgrounds.Grey flex="0 1 100%">
						<pools.CacheRoot>
							<pools.FromURL mt="10px" />
						</pools.CacheRoot>
					</layouts.backgrounds.Grey>
				}
			/>

			<Route path="/:id/studio/*" element={<studio.Routes />} />
			<Route path="/:id/google/responsive" element={<GoogleAdsResponsiveFromURL />} />
			<Route path="/:id/google/published" element={<GoogleAdsFromURL />} />
			<Route path="/:id/google/create" element={<GoogleCreativeFromURL />} />
			<Route path="/:id/google/suggestions" element={<SuggestionsGoogleFromURL />} />
			<Route path="/:id/facebook/links" element={<FacebookAdsFromURL />} />
			<Route path="/:id/facebook/create" element={<FacebookCreativeFromURL />} />
			<Route path="/:id/facebook/suggestions" element={<SuggestionsFacebookFromURL />} />
			<Route path="/:id/metrics" element={<MetricsFromURL />} />
			<Route path="*" element={<pools.Container urlgen={routing.ads.index} />} />
		</Routes>
	)
}

import React, { useContext } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"
import * as context from "./adgen.link.context"
import Headlines from "./adgen.link.generated.headline"
import Descriptions from "./adgen.link.generated.description"
import Messages from "./adgen.link.generated.message"
import { default as Preview } from "./adgen.link.preview"
import { FormContainer, AccordionHeader, inlined, EditField } from "ads/adgen/adgen.content.layouts"
import * as media from "media"
import * as brands from "brands"
import * as icons from "icons"
import classnames from "classnames"
import * as adgen from "ads/adgen"
import * as textgen from "ads/textgen"

interface props extends layouts.containers.FlexProps {
	genctx: context.context
	onChange(...updates: context.mutation[]): void
}

interface Validations {
	isHeadlinePresent: boolean
	isDescriptionPresent: boolean
	isMessagePresent: boolean
	isImagePresent: boolean
}

export function PreviewFromContext(): JSX.Element {
	const genctx = useContext(context.Context)
	return <Preview content={context.form.adlinkcontent(genctx.content)} media={genctx.content.media} pt="10%" />
}

export default function Form(props: props): JSX.Element {
	const { genctx, onChange } = props
	const maxItemsCount = 1
	const zerod = api.ads.link.zero()
	const brand = brands.caching.useCached()
	const adgenConstants = context.adgenconstants()

	const validations = {
		isHeadlinePresent: genctx.content.name.accepted !== zerod.name,
		isDescriptionPresent: genctx.content.description.accepted !== zerod.description,
		isMessagePresent: genctx.content.message.accepted !== zerod.message,
		isImagePresent: genctx.content.media.id !== zerod.media_id,
	} as Validations

	const validationMessage = (isPresent: boolean) => {
		if (isPresent) return null

		return <layouts.containers.box fontSize="10px">Minimum {maxItemsCount} required</layouts.containers.box>
	}

	const validationLengthMessage = (maxLength: number) => {
		return (
			<layouts.containers.box fontSize="10px">
				Text over {maxLength} characters is allowed but may be truncated.
			</layouts.containers.box>
		)
	}

	const onImageClick = (media: media.api.Media, url: string) => {
		onChange(
			context.mutate.content({ media: { id: media.content_digest, url: url } }),
			context.mutate.generated(<PreviewFromContext key="images" />),
		)
	}

	return (
		<>
			<layouts.accordions.Container mb="16px" minHeight="25px" borderBottom={layouts.theme.borders.grey.light}>
				<layouts.containers.box
					tabIndex={0}
					onFocus={(evt) =>
						onChange(context.mutate.generated(<Messages key="messages" onChange={onChange}></Messages>))
					}
				>
					<layouts.accordions.Description>
						<AccordionHeader active={genctx.generated.key === "messages"}>
							Primary Text {validations.isMessagePresent ? maxItemsCount : 0}/{maxItemsCount}
						</AccordionHeader>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex className="accordion-content" flexDirection="column" width="100%">
							<layouts.containers.flex fontSize="10px" fontWeight="400">
								<EditField
									required
									content={genctx.content.message.accepted}
									maxLength={adgenConstants.maxPrimaryTextLength}
									onChange={(val) =>
										onChange(
											context.mutate.content({
												message: {
													...genctx.content.message,
													accepted: val,
												},
											}),
										)
									}
								/>
								<layouts.containers.box
									mt="32px"
									onClick={(e) =>
										onChange(
											context.mutate.rejected({ ...genctx.content.message, feedback: textgen.Feedback.IGNORED }),
											context.mutate.content({ ...genctx.content, message: adgen.text.sample("") }),
										)
									}
								>
									<icons.checkboxes.dismiss
										fill={
											validations.isMessagePresent
												? layouts.theme.colors.grey.dark10
												: layouts.theme.colors.grey.light30
										}
									></icons.checkboxes.dismiss>
								</layouts.containers.box>
							</layouts.containers.flex>
							{validationMessage(validations.isMessagePresent)}
							{genctx.content.message.accepted.length > adgenConstants.maxPrimaryTextLength &&
								validationLengthMessage(adgenConstants.maxPrimaryTextLength)}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
			<layouts.accordions.Container mb="16px" minHeight="25px" borderBottom={layouts.theme.borders.grey.light}>
				<layouts.containers.box
					tabIndex={1}
					onFocus={(evt) =>
						onChange(context.mutate.generated(<Headlines key="headlines" onChange={onChange}></Headlines>))
					}
				>
					<layouts.accordions.Description fontSize="30px" textAlign="right">
						<AccordionHeader active={genctx.generated.key === "headlines"}>
							Headlines {validations.isHeadlinePresent ? maxItemsCount : 0}/{maxItemsCount}
						</AccordionHeader>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex className="accordion-content" flexDirection="column" width="100%">
							<layouts.containers.flex fontSize="10px" fontWeight="400">
								<EditField
									required
									content={genctx.content.name.accepted}
									maxLength={adgenConstants.maxHeadlineLength}
									onChange={(val) =>
										onChange(
											context.mutate.content({
												name: {
													...genctx.content.name,
													accepted: val,
												},
											}),
										)
									}
								/>
								<layouts.containers.box
									mt="32px"
									onClick={(e) => {
										onChange(
											context.mutate.rejected({ ...genctx.content.name, feedback: textgen.Feedback.IGNORED }),
											context.mutate.content({
												name: adgen.text.sample(""),
											}),
										)
									}}
								>
									<icons.checkboxes.dismiss
										fill={
											validations.isHeadlinePresent
												? layouts.theme.colors.grey.dark10
												: layouts.theme.colors.grey.light30
										}
									></icons.checkboxes.dismiss>
								</layouts.containers.box>
							</layouts.containers.flex>
							{validationMessage(validations.isHeadlinePresent)}
							{genctx.content.name.accepted.length > adgenConstants.maxHeadlineLength &&
								validationLengthMessage(adgenConstants.maxHeadlineLength)}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
			<layouts.accordions.Container mb="16px" minHeight="25px" borderBottom={layouts.theme.borders.grey.light}>
				<layouts.containers.box
					tabIndex={2}
					onFocus={(evt) =>
						onChange(context.mutate.generated(<Descriptions key="descriptions" onChange={onChange}></Descriptions>))
					}
				>
					<layouts.accordions.Description>
						<AccordionHeader active={genctx.generated.key === "descriptions"}>
							Descriptions {validations.isDescriptionPresent ? maxItemsCount : 0}/{maxItemsCount}
						</AccordionHeader>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex className="accordion-content" flexDirection="column" width="100%">
							<layouts.containers.flex fontSize="10px" fontWeight="400">
								<EditField
									required={true}
									content={genctx.content.description.accepted}
									maxLength={adgenConstants.maxDescriptionLength}
									onChange={(val) =>
										onChange(
											context.mutate.content({
												description: {
													...genctx.content.description,
													accepted: val,
												},
											}),
										)
									}
								/>
								<layouts.containers.box
									mt="32px"
									onClick={(e) =>
										onChange(
											context.mutate.rejected({ ...genctx.content.description, feedback: textgen.Feedback.IGNORED }),
											context.mutate.content({ ...genctx.content, description: adgen.text.sample("") }),
										)
									}
								>
									<icons.checkboxes.dismiss
										fill={
											validations.isDescriptionPresent
												? layouts.theme.colors.grey.dark10
												: layouts.theme.colors.grey.light30
										}
									></icons.checkboxes.dismiss>
								</layouts.containers.box>
							</layouts.containers.flex>
							{validationMessage(validations.isDescriptionPresent)}
							{genctx.content.description.accepted.length > adgenConstants.maxDescriptionLength &&
								validationLengthMessage(adgenConstants.maxDescriptionLength)}
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
			<layouts.accordions.Container mb="16px" minHeight="25px" borderBottom={layouts.theme.borders.grey.light}>
				<layouts.containers.box
					tabIndex={3}
					onFocus={(evt) => onChange(context.mutate.generated(<PreviewFromContext key="images" />))}
				>
					<layouts.accordions.Description>
						<AccordionHeader active={genctx.generated.key === "images"}>
							Image {validations.isImagePresent ? maxItemsCount : 0}/{maxItemsCount}
						</AccordionHeader>
					</layouts.accordions.Description>
					<layouts.accordions.Content>
						<layouts.containers.flex className="accordion-content" flexDirection="column" width="100%">
							<media.Container mediaPath={brands.routing.media(brand.id)} onImageClick={onImageClick} my="10px" />
						</layouts.containers.flex>
					</layouts.accordions.Content>
				</layouts.containers.box>
			</layouts.accordions.Container>
			<FormContainer className="fields">
				<layouts.containers.flex flexDirection="column" mt="12px">
					<inputs.Text
						defaultValue={genctx.content.call_to_action.value?.link}
						className={classnames(inlined)}
						placeholder="Website URL (https://example.com/)"
						onFocus={(evt) => onChange(context.mutate.generated(<PreviewFromContext />))}
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							onChange(
								context.mutate.content({
									call_to_action: {
										...genctx.content.call_to_action,
										value: { ...genctx.content.call_to_action.value, link: evt.currentTarget.value },
									},
								}),
							)
						}
					/>
				</layouts.containers.flex>
				<layouts.containers.flex flexDirection="column" mt="12px">
					<inputs.Text
						defaultValue={genctx.content.call_to_action?.type}
						className={classnames(inlined)}
						placeholder="Call to Action"
						onFocus={(evt) => onChange(context.mutate.generated(<PreviewFromContext />))}
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							onChange(
								context.mutate.content({
									call_to_action: { ...genctx.content.call_to_action, type: evt.currentTarget.value },
								}),
							)
						}
					/>
				</layouts.containers.flex>
			</FormContainer>
		</>
	)
}

import React, { useState } from "react"
import * as layouts from "layouts"
import * as inputs from "inputs"
import * as api from "./api"
import * as typography from "typography"
import { Brand } from "./account.brand"
import styled from "@emotion/styled"

interface props extends layouts.containers.ContainerProps {
	onChange(b: Brand): void
}

const Select = styled.select`
	width: 100%;
	height: 48px;
	background: ${layouts.theme.colors.white};
	color: ${layouts.theme.colors.grey.dark20};
	border: none;
	&:hover {
		box-shadow: none;
		border: none;
	}
	&:focus {
		outline: 0;
	}
`

export function New(props: props): JSX.Element {
	const [brand, setBrand] = useState(api.zero())

	const { onChange, ...rest } = props

	const disabled = brand.description.length === 0

	return (
		<layouts.containers.box styled background={layouts.theme.colors.grey.bg} {...rest}>
			<typography.h3 color={layouts.theme.colors.grey.medium} p="50px" textAlign="left">
				Add New Brand
			</typography.h3>
			<Form current={brand} onChange={(b: Brand) => setBrand(b)} />
			<layouts.containers.flex justifyContent="right" width="965px">
				<layouts.buttons.primary
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					disabled={disabled}
					onClick={() => onChange(brand)}
				>
					Save
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</layouts.containers.box>
	)
}

export function Edit(props: props & { current: Brand }): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	const { current, onChange, ...rest } = props

	const [brand, setBrand] = useState(current)

	const disabled = brand.description.length === 0

	return (
		<layouts.containers.box styled width="1100px" height="900px" background={layouts.theme.colors.grey.bg} {...rest}>
			<typography.h3 color={layouts.theme.colors.grey.medium} p="50px" textAlign="left">
				Edit Brand
			</typography.h3>
			<Form current={brand} onChange={(b: Brand) => setBrand(b)} />
			<layouts.containers.flex justifyContent="right" width="965px">
				<layouts.buttons.outline
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					onClick={() => mtoggle(undefined)}
				>
					Cancel
				</layouts.buttons.outline>
				<layouts.buttons.primary
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					disabled={disabled}
					onClick={() => onChange(brand)}
				>
					Save
				</layouts.buttons.primary>
			</layouts.containers.flex>
		</layouts.containers.box>
	)
}

export function AdConnect(): JSX.Element {
	const mtoggle = layouts.modals.useToggle()
	return (
		<layouts.containers.box styled width="1100px" height="900px" background={layouts.theme.colors.grey.bg}>
			<typography.h3 color={layouts.theme.colors.grey.medium} p="50px" textAlign="left">
				Connect to Ad platform
			</typography.h3>
			<layouts.containers.flex justifyContent="center" pb="50px">
				<layouts.buttons.primary
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					onClick={() => console.log("Connect to Google")}
				>
					Connect to Google
				</layouts.buttons.primary>
			</layouts.containers.flex>
			<layouts.containers.flex justifyContent="center" pb="50px">
				<layouts.buttons.primary
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					onClick={() => console.log("Connect to Facebook")}
				>
					Connect to Facebook
				</layouts.buttons.primary>
			</layouts.containers.flex>
			<layouts.containers.flex justifyContent="center" pt="50px">
				<layouts.buttons.outline
					m="10px"
					width="220px"
					height="57px"
					borderRadius="37px"
					onClick={() => mtoggle(undefined)}
				>
					Cancel
				</layouts.buttons.outline>
			</layouts.containers.flex>
		</layouts.containers.box>
	)
}

export default function Form(props: props & { current: Brand }): JSX.Element {
	const { current, onChange } = props
	const [tone, setTone] = useState("")

	return (
		<layouts.forms.Container p="25px">
			<layouts.forms.Group display="block">
				<layouts.containers.flex py="50px" width="100%" flexDirection="column">
					<layouts.containers.flex
						width="100%"
						textAlign="center"
						pb="20px"
						display="inline-flex"
						justifyContent="center"
					>
						<layouts.containers.flex width="400px" p="10px">
							<inputs.Text
								className="outline"
								height="70px"
								placeholder="Brand Name"
								defaultValue={current.description}
								onChange={(evt) => onChange({ ...current, description: evt.currentTarget.value })}
								autoFocus
							/>
						</layouts.containers.flex>
						<layouts.containers.flex width="400px" p="10px">
							<inputs.Text
								className="outline"
								height="70px"
								defaultValue={current.domain}
								placeholder="Brand Website https://example.com"
								onChange={(evt) => onChange({ ...current, domain: evt.currentTarget.value })}
							/>
						</layouts.containers.flex>
					</layouts.containers.flex>
					{/* disable tone until we're ready */}
					{<></> || (
						<layouts.containers.flex
							width="50%"
							textAlign="center"
							pb="20px"
							display="inline-flex"
							justifyContent="right"
						>
							<layouts.containers.flex width="400px" height="70px" p="10px">
								<layouts.containers.box
									width="100%"
									maxHeight="70px"
									borderRadius="30px"
									background={layouts.theme.colors.white}
									pt="10px"
									paddingX="40px"
								>
									<Select value={tone} onChange={(evt) => setTone(evt.target.value)}>
										<option value="" disabled>
											Tone
										</option>
										<option value="1">First</option>
										<option value="2">Second</option>
										<option value="3">Third</option>
										<option value="4">Fourth</option>
									</Select>
								</layouts.containers.box>
							</layouts.containers.flex>
						</layouts.containers.flex>
					)}
				</layouts.containers.flex>
			</layouts.forms.Group>
		</layouts.forms.Container>
	)
}
